import React from "react";
import { Link } from "react-router-dom";
import { FaFacebook, FaTwitter, FaInstagram } from "react-icons/fa";

const Footer = ({ isDarkMode }) => (
  <footer
    className={`app-footer text-center mt-4 py-3 ${
      isDarkMode ? "bg-dark text-white" : "bg-light"
    }`}
  >
    <div className="container">
      <p>&copy; {new Date().getFullYear()} Perks FOMO. All rights reserved.</p>
      <p>
        <Link
          to="/contact-us"
          className={`text-decoration-none ${
            isDarkMode ? "text-white" : "text-dark"
          }`}
        >
          Contact Us
        </Link>{" "}
        |{" "}
        <Link
          to="/privacy-policy"
          className={`text-decoration-none ${
            isDarkMode ? "text-white" : "text-dark"
          }`}
        >
          Privacy Policy
        </Link>{" "}
        |{" "}
        <Link
          to="/terms-of-service"
          className={`text-decoration-none ${
            isDarkMode ? "text-white" : "text-dark"
          }`}
        >
          Terms of Service
        </Link>
      </p>
      <p>
        2637 E Atlantic Blvs #1140 Pompano Beach, FL 33069 | Email:
        support@perksfomo.com | Phone: (123) 456-7890
      </p>
      <p>
        <a
          href="https://facebook.com/perksfomo"
          className={`text-decoration-none me-2 ${
            isDarkMode ? "text-white" : "text-dark"
          }`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaFacebook />
        </a>
        <a
          href="https://twitter.com/perksfomo"
          className={`text-decoration-none me-2 ${
            isDarkMode ? "text-white" : "text-dark"
          }`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaTwitter />
        </a>
        <a
          href="https://instagram.com/perksfomo"
          className={`text-decoration-none ${
            isDarkMode ? "text-white" : "text-dark"
          }`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaInstagram />
        </a>
      </p>
    </div>
  </footer>
);

export default Footer;
