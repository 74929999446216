import React, { useEffect, useState } from "react";
import ContactForm from "../components/ContactForm";

const ContactUsPage = ({ user, isDarkMode }) => {
  const [email, setEmail] = useState("");

  useEffect(() => {
    if (user) {
      setEmail(user.email);
    }
  }, [user]);

  return (
    <div className={`container mt-5 ${isDarkMode ? "bg-dark text-white" : ""}`}>
      <h2>Contact Us</h2>
      <ContactForm user={user} isDarkMode={isDarkMode} email={email} />
    </div>
  );
};

export default ContactUsPage;
