import React from "react";
import { FaFacebook, FaTwitter, FaEnvelope, FaSms } from "react-icons/fa"; // Import social media icons
import {
  generateShareableURL,
  generateEmailContent,
  generateSMSContent,
} from "../utils/shareUtils"; // Import utility functions
import { trackButtonClick } from "../utils/FacebookPixel"; // Import the function

const SocialShareButtons = ({
  searchOption,
  issuer,
  cardType,
  cardName,
  benefit,
  isDarkMode,
}) => {
  const shareToSocialMedia = (platform) => {
    const url = generateShareableURL(
      searchOption || "",
      issuer || "",
      cardType || "",
      cardName || "",
      benefit || ""
    );
    const text = "Check out these credit card perks!";
    let shareUrl = "";

    switch (platform) {
      case "facebook":
        shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}`;
        trackButtonClick("Share on Facebook"); // Track button click
        break;
      case "twitter":
        shareUrl = `https://twitter.com/intent/tweet?url=${url}&text=${text}`;
        trackButtonClick("Share on Twitter"); // Track button click
        break;
      case "email":
        const emailContent = generateEmailContent(
          searchOption || "",
          issuer || "",
          cardType || "",
          cardName || "",
          benefit || ""
        );
        shareUrl = `mailto:?subject=${text}&body=${encodeURIComponent(
          emailContent
        )}`;
        trackButtonClick("Share via Email"); // Track button click
        break;
      case "sms":
        const smsContent = generateSMSContent(
          searchOption || "",
          issuer || "",
          cardType || "",
          cardName || "",
          benefit || ""
        );
        shareUrl = `sms:?body=${encodeURIComponent(smsContent)}`;
        trackButtonClick("Share via SMS"); // Track button click
        break;
      default:
        return;
    }

    window.open(shareUrl, "_blank");
  };

  return (
    <div className={`social-share-section ${isDarkMode ? "dark-mode" : ""}`}>
      <p>Share your results on social media or via SMS:</p>
      <div className="social-share-buttons">
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => shareToSocialMedia("facebook")}
        >
          <FaFacebook /> <span>Share on Facebook</span>
        </button>
        <button
          type="button"
          className="btn btn-info"
          onClick={() => shareToSocialMedia("twitter")}
        >
          <FaTwitter /> <span>Share on Twitter</span>
        </button>
        <button
          type="button"
          className="btn btn-danger"
          onClick={() => shareToSocialMedia("email")}
        >
          <FaEnvelope /> <span>Share via Email</span>
        </button>
        <button
          type="button"
          className="btn btn-success"
          onClick={() => shareToSocialMedia("sms")}
        >
          <FaSms /> <span>Share via SMS</span>
        </button>
      </div>
    </div>
  );
};

export default SocialShareButtons;
