import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import {
  FaHome,
  FaUser,
  FaSignOutAlt,
  FaMoon,
  FaSun,
  FaSignInAlt,
  FaClipboardList,
} from "react-icons/fa";

const isSignupEnabled = process.env.REACT_APP_ENABLE_SIGNUP === "true";
const isLoginEnabled = process.env.REACT_APP_ENABLE_LOGIN === "true";

const Sidebar = ({
  isOpen,
  toggleSidebar,
  handleSignOut,
  isDarkMode,
  handleToggleDarkMode,
  user,
}) => {
  const sidebarRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        toggleSidebar();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, toggleSidebar]);

  if (process.env.REACT_APP_WHICH_ENV !== "production") {
    console.log("Sidebar user:", user);
  }

  return (
    <div
      ref={sidebarRef}
      className={`offcanvas offcanvas-start ${isOpen ? "show" : ""} ${
        isDarkMode ? "bg-dark text-white" : ""
      }`}
      tabIndex="-1"
      style={{ width: "250px" }}
    >
      <div className="offcanvas-header">
        <h5 className="offcanvas-title">Menu</h5>
        <button
          type="button"
          className="btn-close text-reset"
          onClick={toggleSidebar}
        ></button>
      </div>
      <div className="offcanvas-body p-0">
        {user && user.displayName && (
          <div className="p-3">
            <h6 className="mb-0">Hi, {user.displayName}!</h6>
          </div>
        )}
        <nav className="nav flex-column">
          <Link
            to="/"
            className={`nav-link d-flex align-items-center ${
              isDarkMode ? "text-white" : "text-primary"
            }`}
            onClick={toggleSidebar}
          >
            <FaHome className="me-2" /> Home
          </Link>
          {user && (
            <Link
              to="/profile"
              className={`nav-link d-flex align-items-center ${
                isDarkMode ? "text-white" : "text-primary"
              }`}
              onClick={toggleSidebar}
            >
              <FaUser className="me-2" /> Profile
            </Link>
          )}
          {!user && (
            <Link
              to="/waitlist"
              className={`nav-link d-flex align-items-center ${
                isDarkMode ? "text-white" : "text-primary"
              }`}
              onClick={toggleSidebar}
            >
              <FaClipboardList className="me-2" /> Join Waitlist
            </Link>
          )}
          <Link
            to="/contact-us"
            className={`nav-link d-flex align-items-center ${
              isDarkMode ? "text-white" : "text-primary"
            }`}
            onClick={toggleSidebar}
          >
            <FaClipboardList className="me-2" /> Contact Us
          </Link>
          {!user && isLoginEnabled && (
            <Link
              to="/login"
              className={`nav-link d-flex align-items-center ${
                isDarkMode ? "text-white" : "text-primary"
              }`}
              onClick={toggleSidebar}
            >
              <FaSignInAlt className="me-2" /> Sign In
            </Link>
          )}
          {!user && isSignupEnabled && (
            <Link
              to="/signup"
              className={`nav-link d-flex align-items-center ${
                isDarkMode ? "text-white" : "text-primary"
              }`}
              onClick={toggleSidebar}
            >
              <FaSignInAlt className="me-2" /> Sign Up
            </Link>
          )}
          {user && (
            <button
              className={`btn btn-link nav-link d-flex align-items-center ${
                isDarkMode ? "text-white" : "text-primary"
              }`}
              onClick={handleSignOut}
            >
              <FaSignOutAlt className="me-2" /> Sign Out
            </button>
          )}
          <div className="nav-item mt-3 px-3">
            <div className="form-check form-switch d-flex align-items-center">
              <input
                className="form-check-input"
                type="checkbox"
                id="darkModeToggle"
                checked={isDarkMode}
                onChange={handleToggleDarkMode}
              />
              <label
                className={`form-check-label ms-2 ${
                  isDarkMode ? "text-white" : "text-primary"
                }`}
                htmlFor="darkModeToggle"
              >
                {isDarkMode ? <FaMoon /> : <FaSun />}{" "}
                {isDarkMode ? "Dark Mode" : "Light Mode"}
              </label>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Sidebar;
